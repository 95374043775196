import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/modules/interfaces';
import { changePassword, ForgotPassword, getPlaces, getUser, Login, Register } from 'src/app/modules/Users';
import { BehaviorSubject, Observable } from 'rxjs';
import { RouteEnum } from 'src/app/modules/route.enum';
import { EndpointsEnum } from 'src/app/modules/endpoints.enum';
import { CookieService } from 'ngx-cookie-service';



@Injectable({
    providedIn: 'root',
})
export class UserProfileService {
    userId: string = ''
    private userIdSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.getUserIdFromCookies());
    public userId$ = this.userIdSubject.asObservable();
    constructor(
        private http: HttpClient, 
        private cookieService: CookieService,
    ) {
     }
    getUserId(): string {
        return this.userIdSubject.value;
    }

    private getUserIdFromCookies(): string {
        return this.cookieService.get('userID') || '';
    }

    // Set the userId when the user logs in
    setUserId(userId: string): void {
        this.cookieService.set('userID', userId);
        this.userIdSubject.next(userId);
    }

    // Clear the userId when the user logs out
    clearUserId(): void {
        this.cookieService.delete('userID');
        this.userIdSubject.next('');
    }

    getUser(userId?:any):Observable<getUser[]>{
        return this.http.get<getUser[]>(`${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.getUser}`, {params:{userId}});
    }
    
    
    register(data: any, ecommerce: boolean = false): Observable<any> {
        const url = `${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.register}?ecommerce=${ecommerce}`;
        return this.http.post<any>(url, data);
    }
    
    
    resendVerificationEmail(data:any): Observable<Register[]> {
        return this.http.post<Register[]>(`${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.resendVerificationEmail}`, data);
    }
    
    login(data:any): Observable<Login> {
        return this.http.post<Login>(`${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.login}`, data);
    }
    
    forgotpassword(data:any): Observable<ForgotPassword[]> {
        return this.http.post<ForgotPassword[]>(`${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.forgotpassword}?ecommerce=true`, data);
    }
    
    changepassword(data:any, ecommerce: boolean = false): Observable<changePassword[]> {
        const url = `${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.changepassword}?ecommerce=${ecommerce}`;
        return this.http.post<changePassword[]>(url, data);
    }
    
    
    getAllPlaces(): Observable<getPlaces[]> {
        return this.http.get<getPlaces[]>(`${environment.conomeUrl}/api/${RouteEnum.clientConomeApi}/${EndpointsEnum.getPlaces}`);
    }
    
}
