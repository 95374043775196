<div id="intro" class="p-5 text-center bg-image shadow-1-strong"
  [ngStyle]="{ 'background-image': 'url(assets/images/COMING.jpg)' }">
  <div class="mask" style="background-color: rgba(0, 0, 0, 0.7);">
    <div class="d-flex justify-content-center align-items-center h-100">
      <div class="text-white px-4" data-mdb-theme="dark">
        <!-- Time Counter -->
        <h3 id="time-counter" class="border border-white my-4 p-4">{{ timeLeft }}</h3>
      </div>
    </div>
  </div>
</div>
