import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrl: './brands.component.scss'
})
export class BrandsComponent {

  translateService = inject(TranslateService);
  cmsService = inject(LozztoysCmsService);
  route = inject(ActivatedRoute);
  sharedService = inject(DataSharedService);
  router = inject(Router);

  brands: any[] = [];
  currentLanguage = this.translateService.currentLang;
  filteredBrands: any[] = []; 
  searchText = '';
  
  itemsPerPage: number = 20; 
  currentPage: number = 1; 
  brandsCount: number = 0; 
  showMoreButton: boolean = true; 
  barFilled: number = 0; 
  conomeUrl = environment.conomeUrl;
  breadCrumbItems!: any;

  

  constructor() { }

  ngOnInit(): void {
    this.getBrands();
    this.translateBreadcrumbItems();
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLanguage = lang.lang;
      this.translateBreadcrumbItems();

    })
    this.translateService.onLangChange.subscribe(() => {
    })
  } 

  private async translateBreadcrumbItems() {
    const homeLabel = await this.translateService.get("menu.HOME").toPromise();
    const firstLabel = await this.translateService.get("menu.BRANDS").toPromise();

    this.breadCrumbItems = [
        { label: homeLabel, link: '/home' },
        { label: firstLabel, link: '/brands' },
    ];
}

  columnNameByLanguage(brand:any){
    if(this.currentLanguage === 'sq'){}
    const lang = "name"+this.currentLanguage.toUpperCase();
    return brand[lang];
}

getBrands() {
  this.cmsService.getBrandsFromCMS(this.itemsPerPage, this.currentPage).subscribe((response: any) => {
    this.brands = [...this.brands, ...response.data];
    this.filteredBrands = [...this.brands]; 
    this.brandsCount = response.meta?.pagination?.total || this.brands.length;

    this.barFilled = (this.brands.length / this.brandsCount) * 100;

    this.showMoreButton = this.brands.length < this.brandsCount;

  });
}

searchBrands() {
  const searchTerm = this.searchText.trim();

  if (searchTerm) {
    this.cmsService.searchBrandsFromCMS(searchTerm, this.currentLanguage.toUpperCase()).subscribe((response: any) => {
      this.brands = response.data; 
      this.filteredBrands = [...this.brands]; 
      this.brandsCount = response.meta?.pagination?.total || this.brands.length;

      this.barFilled = (this.brands.length / this.brandsCount) * 100;


      this.showMoreButton = this.brands.length < this.brandsCount;

    });
  } else {
    this.currentPage = 1; 
    this.brands = [];
    this.getBrands();
  }
}


clearSearch() {
  this.searchText = '';

  this.currentPage = 1;
  this.brands = [];

  this.getBrands();

}


showMore() {
  this.currentPage++; 
  this.getBrands(); 
}

  redirectTo(link: any) {
    if (link) {
      if (typeof link === 'string') {
        if (link.startsWith('http://') || link.startsWith('https://')) {
          window.location.href = link;
        } else {
          this.router.navigate([link]);
        }
      } else if (typeof link === 'object') {
        if (link.categoryIds) {
          this.sharedService.setCategoryIds(link.categoryIds); 
          this.router.navigate([link.path]);
        } else if (link.brandIds) {
          this.sharedService.setBrandIds(link.brandIds); 
          this.router.navigate([link.path]);
        } 
        else if (link.categorybrandIds) {
            const categoryIds = link.categorybrandIds
              .filter((item: any) => item.type === 'category')
              .map((item: any) => item.id);
            const brandIds = link.categorybrandIds
              .filter((item: any) => item.type === 'brand')
              .map((item: any) => item.id);
    
    
            this.sharedService.setCategoryIds(categoryIds);
            this.sharedService.setBrandIds(brandIds);
    
            this.router.navigate([link.path]);
        }        
        else {
          console.error('Invalid link object:', link);
        }
      }
    }
  }

redirectToProducts(link: string, brand: any){
    this.redirectTo(link)
     this.sharedService.setBrandIds([brand.id]);
}
}
