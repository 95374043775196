import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { SeoService } from 'src/app/services/seoService/seo.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent {
    
    breadCrumbItems!: any;
    contact: any[] = [];
    contactTitle = '';
    currentLanguage = this.translateService.currentLang;
    cmsUrl = environment.backendUrl;
    contactForm: FormGroup;
    constructor(
        private cmsService: LozztoysCmsService, 
        private translateService: TranslateService, 
        private formBuilder: FormBuilder,  
        private seoService: SeoService,
        private emailService: EmailServiceService,) {

        this.contactForm = this.formBuilder.group({
            name: ['', Validators.required],
            from: ['', [Validators.required, Validators.email]],
            phone:[''],
            subject: ['', Validators.required],
            text: ['', Validators.required]
        });

    }


    ngOnInit(){
        this.getContactSection();
        this.translateBreadcrumbItems(); // Translate initially
        // Subscribe to language changes and update translations
        this.translateService.onLangChange.subscribe((res) => {
            if (this.currentLanguage !== res.lang) {
                this.currentLanguage = res.lang;
                this.getContactSection();
                this.translateBreadcrumbItems();
            }
        });
    }

    //is used for banner data
    private async translateBreadcrumbItems() {
        const homeLabel = await this.translateService.get("menu.HOME").toPromise();
        const firstLabel = await this.translateService.get("menu.CONTACT").toPromise();

        this.breadCrumbItems = [
            { label: homeLabel, link: '/home' },
            { label: firstLabel, link: '/contact' },
        ];
    }

    getContactSection(){
        const locale = this.currentLanguage;
        const populate = 'deep';
        const populateAndLocale = `?populate=${populate}&locale=${locale}`;
        this.cmsService.getContact(populateAndLocale).subscribe({
            next: (res) => {
                this.contactTitle = res.data.attributes.title;
                this.seoService.initializeSeo(res.data.attributes.seo);
                this.contact = res.data.attributes.contact_info.map((item:any) => {
                    return {
                        title: item.Title,
                        subtitle: item.subtitle,
                        icon: this.cmsUrl + item.icon.data.attributes.url
                    }
                })
            },
            error: (err) => {
                console.error(err);
            }
        })
    }

    sendMessage():void{
        if(this.contactForm.valid){
            
            const predefinedMessage = `${this.translateService.instant("contact.EMAIL-SENT-BY")} ${this.contactForm.value.name} ${this.translateService.instant("contact.WITH-THIS-EMAIL")} ${this.contactForm.value.from} ${this.translateService.instant("contact.AND-PHONE-NUMBER")} <a href="tel:${this.contactForm.value.phone}">${this.contactForm.value.phone}</a>`;
            this.contactForm.value.text = this.contactForm.value.text + '<br><br>' + predefinedMessage;
            const emailData = {
              to: this.contactForm.value.from,
              subject: this.contactForm.value.subject,
              text: "",
              html:  this.contactForm.value.text, 
            };
            this.emailService.sendEmail(emailData).subscribe(
                (response)=>{
                    Swal.fire({
                        title: '',
                        text: this.translateService.instant("contact.EMAIL-SUCCESSFULL"),
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#fe696a',
                        customClass: {
                        confirmButton: 'btn-primary'
                        }
                      })
                    this.contactForm.reset();
                },
                (error)=>{
                    console.error(error);
                }
            );
        }
    }
}
