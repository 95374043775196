<app-banner title="{{'menu.BRAMDS' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-banner>
<!-- Toolbar-->
<div class="mb-4 mt-4 container">
    <div class="bg-light shadow-lg rounded-3">
      <div class="d-flex row">
        <!-- Search-->
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="input-group pe-3 d-flex align-items-center">
              <input
                class="form-control search bg-transparent rounded-0 border-0 shadow-none ps-5 py-4"
                type="text"
                translate
                placeholder="{{currentLanguage === 'en' ? 'Search...' : currentLanguage === 'sq' ? 'Kërko...' : 'Пребарај...'}}"
                [(ngModel)]="searchText"
                (keyup.enter)="searchBrands()"
              />
              <i class="ci-search position-absolute top-50 start-0 translate-middle-y zindex-5 ms-3 fs-lg text-muted"></i>
              <button class="button-outline icon-btn ms-2" type="button" (click)="searchBrands()">
                <i class="ci-search"></i>
              </button>
              <!-- Cancel button -->
              <button
                class="button-outline icon-btn ms-2"
                type="button"
                *ngIf="searchText"
                (click)="clearSearch()"
              >
                <i class="ci-close"></i>
              </button>
            </div>
          </div>
            
      </div>
    </div>
  </div>

<section class="container tab-content py-sm-5 margin-2-top">
    <div class="row pt-3 pt-sm-0 px-1">
      <div *ngFor="let item of filteredBrands; let i = index;" class="col-lg-3 col-md-3 col-sm-6 mb-grid-gutter">
        <div class="brands-boxes" (click)="redirectToProducts('products', item)">
            <div class="content">
              <img 
                style="height: 100px;" 
                [src]="item.attributes.image && item.attributes.image.startsWith('companies') 
                ? conomeUrl + '/' + item.attributes.image 
                : '../../../assets/images/lozztoys-logo.png'" 
                alt="icon">
              <p class="margin-0">{{columnNameByLanguage(item.attributes)}}</p>                
            </div>
          </div> 
      </div>
    </div>
  
  <!-- Load more button -->
<nav class="d-md-flex justify-content-between align-items-center text-center text-md-start pt-2 pb-4 mb-md-2"
aria-label="Page navigation">
<div class="d-md-flex align-items-center w-100">
  <span class="fs-sm text-muted me-md-3">
    {{"menu.SHOWING" | translate}} {{brands.length}} {{"paginator.OF" | translate}} {{brandsCount}} {{"menu.BRANDS" | translate | lowercase}}
  </span>    
  <ngb-progressbar type="dark" [value]="barFilled" class="progress w-100 my-3 mx-auto mx-md-0" style="max-width: 10rem; height: 4px;"></ngb-progressbar>
</div>
<button *ngIf="showMoreButton" (click)="showMore()" class="btn btn-outline-primary more-btn" type="button">
  {{"menu.LOAD-MORE-BRANDS" | translate}}
</button>
</nav>
  </section>