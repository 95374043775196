import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forget-password-modal',
    standalone: false,
    templateUrl: './forget-password-modal.component.html',
    styleUrl: './forget-password-modal.component.scss'
})
export class ForgetPasswordModalComponent {


    forgotPasswordForm = new FormGroup({

        Username : new FormControl(''),
    
    });

    constructor(private userService: UserProfileService, private modalService: NgbModal,private translate: TranslateService, private emailService: EmailServiceService){

    }

    successTitle='';
    successDesc='';
    failedTitle='';
    failedDesc='';

    ngOnInit(){
        this.loadTranslations();
    
        this.translate.onLangChange.subscribe(() => {
            this.loadTranslations();
        });
      }
    
      loadTranslations() {
        const translationKeys = [
           'login-page.FORGOT-PASSWORD-SUCCESS',
           'login-page.FORGOT-PASSWORD-SUCCESS-DESC',
           'login-page.FORGOT-PASSWORD-FAIL',
           'login-page.FORGOT-PASSWORD-FAIL-DESC',
        ];
    
        this.translate.get(translationKeys).subscribe(translations => {
            this.successTitle = translations['login-page.FORGOT-PASSWORD-SUCCESS'];
            this.successDesc = translations['login-page.FORGOT-PASSWORD-SUCCESS-DESC'];
            this.failedTitle = translations['login-page.FORGOT-PASSWORD-FAIL'];
            this.failedDesc = translations['login-page.FORGOT-PASSWORD-FAIL-DESC'];
        });
    }
  
    forgotPassword(username: any) {
        const data = { ...username };
  
        this.userService.forgotpassword(data).subscribe({
            next: (res:any) => {
                Swal.fire({
                    title: this.successTitle,
                    text: this.successDesc,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
                this.sendEmail("https://lozztoys.com/reset-password", res.userId, res.token);
                this.modalService.dismissAll();

            },
            error: (error) => {
                Swal.fire({
                    title: this.failedTitle,
                    text: this.failedDesc,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    customClass: {
                        confirmButton: 'custom-confirm-button'
                    }
                });
            }
        });
    }
    forgotPasswordButton = '';
    forgotPasswordTitle = '';
    emailSubject = '';
    lozzText = "";

    translatedTextForemail() {
        this.emailSubject = " " + this.translate.instant("forgot-password.EMAIL-SUBJECT");
        this.forgotPasswordTitle = this.translate.instant("forgot-password.RESET-PASSWORD");
        this.lozzText = this.translate.instant("sign-up.EMAIL-TEXT");
        this.forgotPasswordButton = this.translate.instant("forgot-password.RESET-PASSWORD");
      }

    sendEmail(confirmUrl:string, userId: number, token: string) {
        this.translatedTextForemail();
        const subject = this.emailSubject;
        const email = this.forgotPasswordForm.value.Username;
        const resetUrl = `${confirmUrl}?userId=${userId}&token=${token}`;

        const signupHTML = `
        <!DOCTYPE html>
        <html>
        <head>
            <style>
                * {
                    box-sizing: border-box;
                }
            </style>
        </head>
        <body>
            <table align="center" width="100%" cellpadding="0" cellspacing="0" style="background-color: #f5f5f5; padding: 20px;">
                <tr>
                    <td align="center">
                        <!-- Main Container -->
                        <table width="350" cellpadding="0" cellspacing="0" style="background-color: #ffffff; box-shadow: 0px 3px 6px #00000029; padding: 20px; text-align: center; font-family: Arial, sans-serif;">
                            
                            <!-- First Image -->
                            <tr>
                                <td style="padding-bottom: 20px;">
                                    <img src="https://admin.lozztoys.com/uploads/thumbnail_lozz_05983e88f6.png?updatedAt=2024-11-07T10%3A44%3A45.389Z" alt="LozzToys Email" style="width: 180px; height: 150px;">
                                </td>
                            </tr>

                            <!-- Title Text -->
                            <tr>
                                <td style="font-size: 26px; color: #262626; font-weight: 600; padding-bottom: 15px;">
                                    ${this.forgotPasswordTitle}
                                </td>
                            </tr>

                            <!-- Body Text -->
                            <tr>
                                <td style="color: #646464; padding-bottom: 20px;">
                                    ${this.lozzText}
                                </td>
                            </tr>

                            <!-- Confirmation Button -->
                            <tr>
                                <td style="padding-bottom: 15px;">
                                    <a href="${resetUrl}" style="background-color: #f6821f; color: white; text-decoration: none; font-size: 16px; padding: 15px 0; display: inline-block; width: 100%; border-radius: 4px;">
                                        ${this.forgotPasswordButton}
                                    </a>
                                </td>
                            </tr>

                            <!-- Second Image -->
                            <tr>
                                <td>
                                    <img src="https://admin.lozztoys.com/uploads/thumbnail_lozztoys_logo_2f35738e5d.png?updatedAt=2024-11-07T10%3A44%3A18.448Z" alt="LozzToys Logo" style="width: 130px; height: 100px;">
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
        </body>
        </html>
        `;
        const emailData = {
            to: email,
            subject: subject,
            html: signupHTML
        };
        this.emailService.sendEmail(emailData).subscribe();
    }

    closemodal() {
        this.modalService.dismissAll();
    }
}
