import { Component, ElementRef, ViewChild } from '@angular/core';
import { OrderClientConome, OrderConome, OrderDetailConome, Product, Sale, SaleDetail, SalePayments } from '../../modules/interfaces';
import { LOCAL_STORAGE } from '../../modules/constants';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfileService } from 'src/app/services/user/user-profile.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/services/products/products.service';
import { TranslateService } from '@ngx-translate/core';
import { Token } from '@angular/compiler';
import { CartService } from 'src/app/services/cartServices/cart-service.service';
import Swal from 'sweetalert2';
import { EmailServiceService } from 'src/app/services/EmailService/email-service.service';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import * as LZString from 'lz-string';
import { ConomeECommerceService } from 'src/app/services/conomeServices/conome-e-commerce.service';
import { CookieService } from 'ngx-cookie-service';
import { getUser } from 'src/app/modules/Users';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent {

  @ViewChild('paymentFormView') paymentFormView?: ElementRef;
  PortalUrl = "";
  Clientid = "";
  Storetype = "";
  Trantype = "";
  Instalment = "";
  amount = "";
  Currency = "";
  Oid = "";
  OkUrl = "";
  FailUrl = "";
  CallbackUrl = "";
  Lang = "";
  Encoding = "";
  Refreshtime = "";
  Rnd = "";
  Hash = "";
  totalPayment = 0;
  paymentWithCard = false;
  isFromBuyNow : boolean = false
  appliedCoupon: any = '';
  appliedCouponObject: any;
  discountPercentage: number = 0;
  discount: number = 0;
  withDiscount: number = 0;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private userService: UserProfileService,
    private http: HttpClient,
    private productsService: ProductsService,
    private translate: TranslateService,
    private cartService: CartService,
    private emailService: EmailServiceService,
    private router: Router,
    private conomeService: ConomeECommerceService,
    private cookieService: CookieService,
    private userProfileService: UserProfileService,
    private cmsService: LozztoysCmsService
  ) {
    this.formData = this.formBuilder.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]]

    });

    this.formData.valueChanges.subscribe((value) => {

      if (this.formData.status === "VALID") {
        this.isFormValid = true
      } else {
        this.isFormValid = false
      }

    });
  }

  isFormValid!: boolean;
  formData!: UntypedFormGroup;
  submitted = false;
  products: any[] = [];
  productsFromConome: any[] = [];
  totalPrice = 0;
  totalPriceWithShipping: number = 0;
  shippingCost = this.cartService.shippingCost
  userId: string = '';
  userID: number = -1;
  user!: any;
  labelPaymentSuccess = "";
  breadCrumbItems!: any;
  token = this.cookieService.get(LOCAL_STORAGE.TOKEN) || "";
  secretKey = '12345678901234567890123456789012';
  successUrl = this.generateEncryptedUrl(true);
  failUrl = this.generateEncryptedUrl(false);
  companyEmail='';
  today: string = new Date().toLocaleDateString('en-GB');

  //below variables are used to get user data for shipping
  name = "";
  city = "";
  address = "";
  phoneNumber = "";
  email = "";
  paymentMethod = "";

  //below variables are used to get translated text based on current language
  subject = "";
  title = "";
  addressTitle = "";
  cityTitle = "";
  phoneNumberTitle = "";
  nameTitle = "";
  priceTitle = "";
  discountTitle="";
  withDiscountTitle="";
  quantityTitle = "";
  subtotalTitle = "";
  shippingTitle="";
  totalTitle = "";
  currency = "";
  textThankYou = "";
  textForAddress = "";
  textForTable = "";
  textForQuestion = "";
  ownerEmailSubject="";
  newOrder='';
  newOrderText='';
  costumerName='';
  emailAddress='';
  productsTableText=''

  ngOnInit(): void {
    this.appliedCoupon=localStorage.getItem('couponCode')
    this.conomeService.getCompanyInfo().subscribe((res)=>{
      this.companyEmail=res.companyEmail;
    })
    this.getUser();
    this.userProfileService.userId$.subscribe(userId => {
      this.userId = userId
    });

    this.cartService.buyNowProduct$.subscribe(product => {
      if (product) {
        this.isFromBuyNow = true;
        this.products = [product];
        this.updateTotalPrice();

      } else {
        this.getProducts();
        this.isFromBuyNow = false;
        this.cartService.cartProducts$.subscribe(cartProducts => {
          this.products = cartProducts;
          this.updateTotalPrice();
        });
      }
    });

    this.translateBreadcrumbItems(); 
    this.translate.onLangChange.subscribe(() => {
      this.translateBreadcrumbItems();
    });
  }

  goToCartPage(){
    this.router.navigate(['/cart'])
  }

  getUser() {
    if (this.token !== "") {
      this.userID = parseInt(this.cookieService.get("userID"), 10);
      if (this.userID !== 0) {
        this.userService.getUser(this.userID).subscribe(
          (data: getUser[]) => {
            if (data.length > 0) {
              this.user = data[0];
              this.email = data[0].email;
              this.formData.setValue({
                name: data[0].surname ? data[0].name + ' ' + data[0].surname : data[0].name,
                phone: data[0].phoneNumber ?? '',
                address: data[0].address ?? '',
                city: data[0].address ?? '',
                email: data[0].email ?? '',
              })
            }
          },
          (error) => {
            console.error('Error fetching user data', error);
          }
        );
      }
      else {
        this.formData.controls["name"].setValue(this.name)
        this.formData.controls["phone"].setValue(this.phoneNumber)
        this.formData.controls["address"].setValue(this.address)
        this.formData.controls["city"].setValue(this.city)
        this.formData.controls["email"].setValue(this.email)
      }

    }
  }

  calcTotalOnInit() {
    let totalPrice = 0
    this.products.forEach((el: any) => {
      el['total'] = el.sellingPriceWithRebate * el.quantity;
      totalPrice += el.total
    });
    this.totalPrice = totalPrice
  }

  getProducts() {
    this.cartService.fetchCartData(this.userId).subscribe({
      next: (products: any[]) => {
        this.products = products;
        this.updateTotalPrice()
      }
    });
  }

  updateTotalPrice() {
    let totalWithDiscount = 0;
    let totalWithoutDiscount = 0;
    let totalDiscountAmount = 0;
  
    if (this.appliedCoupon) {
      this.cartService.getAppliedCoupon(this.appliedCoupon).subscribe((coupon) => {
        this.appliedCouponObject=coupon;
        const productsArray = coupon.data[0]?.attributes?.products?.data || [];
        const categoriesArray = coupon.data[0]?.attributes?.categories?.data || [];
        const discountPercentage = coupon.data[0]?.attributes?.Discount || 0;
        this.discountPercentage=discountPercentage;

        this.products.forEach((product) => {
          const productPrice = product.productDetails[0].sellingPriceWithRebate * product.quantity;
          const appliesToAllProducts =
            productsArray.length === 0 && categoriesArray.length === 0;
          const appliesToProduct = productsArray.some(
            (p: any) => p.attributes.productID === product.id
          );
          const appliesToCategory = categoriesArray.some(
            (c: any) => Number(c.attributes.category_id) === product.categoryId
          );
  
          if (appliesToAllProducts || appliesToProduct || appliesToCategory) {
            const discountAmount = productPrice * (discountPercentage / 100);
            totalWithDiscount += productPrice - discountAmount;
            totalDiscountAmount += discountAmount;
            this.discount = totalDiscountAmount;
            this.withDiscount=totalWithDiscount;
          } else {
            totalWithoutDiscount += productPrice;
          }
        });
  
        this.totalPriceWithShipping = totalWithDiscount + totalWithoutDiscount + this.shippingCost;
        this.totalPrice = this.cartService.calculateTotalPrice(this.products);
      });
    } else {
      this.totalPrice = this.cartService.calculateTotalPrice(this.products);
      this.withDiscount = this.totalPriceWithShipping = this.cartService.calculateTotalPriceForCheckout(this.products, this.shippingCost);
    }
  }

  get form() {
    return this.formData.controls;
  }

  completeorder() {
    this.submitted = true
  }

  get currentLanguage() {
    return this.translate.currentLang;
  }

  payNow() {
    this.http.post(`${environment.backendUrl}/api/orders`, {
      data: {
        userId: this.userId,
        transaction_id: "0050a35e-11c0-11ee-be56-0242ac120002",
        is_success: true,
        total: this.totalPrice,
        status: 'IN-PROGRESS',
        name: this.name,
        city: this.city,
        number: this.phoneNumber,
        email: this.email,
        address: this.address,
        payment: this.paymentMethod,
        is_paid: false
      }
    }).subscribe((order: any) => {
      if (this.paymentMethod == "Card") {
        this.submitCardForm(order.data.id);
      }
      const PRODUCTS = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || "[]")
      // Get details for every product
      for (const product of PRODUCTS) {
        this.http.post(`${environment.backendUrl}/api/orders-details`, {
          data: {
            order_id: order.data.id,
            product: product.id,
            quantity: product.quantity,
            price: product.price,
            name: product.name_en,
            status: 'IN-PROGRESS', // Added default to in progress
            order: {
              connect: [order.data.id]
            }
          }
        }).subscribe((orderDetail: any) => {
          //Update quantities available for each product bought
          for (const product of PRODUCTS) {
            this.updateProductQuantities(product)
          }
        });
      }
      this.sendEmail();
      this.sendEmailForOwner()
      this.formData.reset();

      for (const key in this.formData.controls) {
        this.formData.controls[key].setErrors(null);
      }
      this.removeLocalStorageItem();
      if (this.paymentMethod == "Cash") {
        this.showSuccessMessageAndNavigate();
      }
      this.totalPrice = 0;
      this.cartService.clearShippingCost();
      const productsArray: Product[] = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || '[]');
      this.cartService.updateCartProducts(productsArray);
    },
      (err: any) => {
        this.labelPaymentSuccess = err.error.error.message
      })
  }

  //is used for banner data
  private async translateBreadcrumbItems() {
    const homeLabel = await this.translate.get("menu.HOME").toPromise();
    const firstLabel = await this.translate.get("menu.CART").toPromise();
    const secondLabel = await this.translate.get("menu.CHECKOUT").toPromise();

    this.breadCrumbItems = [
      { label: homeLabel, link: '/home' },
      { label: firstLabel, link: '/cart' },
      { label: secondLabel, link: '/checkout' },
    ];
  }

  //shows modal if the order is added successfully
  showSuccessMessageAndNavigate() {
    Swal.fire({
      title: '', // Your title here
      text: this.translate.instant("checkout.ORDER-SUCCESSFULL"),
      icon: 'success',
      showCancelButton: false,
      confirmButtonText: 'OK',
      confirmButtonColor: '#fe696a',
      customClass: {
        confirmButton: 'btn-primary' // Add your custom class here
      }
    }).then((result) => {
      if (result.isConfirmed) {
        // User clicked "OK" on the swal dialog, so navigate to the 'products' route
        this.router.navigate(['products']);
      }
    });
  }
  //is used to remove products from local storage
  removeLocalStorageItem() {
    localStorage.removeItem(LOCAL_STORAGE.PRODUCTS);
    this.getProducts();
  }
  //is used to update product quantities to check if has product available
  updateProductQuantities(product: Product) {
    this.productsService.updateProductById(product, product.quantity_available - product.quantity).subscribe()
  }

  //for cash payment
  payCash() {
    this.paymentMethod = "Cash";
    this.payNow();
  }

  //for card payment
  payCard() {
    this.paymentMethod = "Card";
    this.payNow();
  }

  //gets translated text from current language from json file
  translatedTextForemail() {
    this.currency = " " + this.translate.instant("currency.CURRENCY_VALUE");
    this.title = this.translate.instant("email_data.TITLE");
    this.addressTitle = this.translate.instant("email_data.ADDRESS_TITLE");
    this.cityTitle = this.translate.instant("email_data.CITY_TITLE");
    this.phoneNumberTitle = this.translate.instant("email_data.PHONE_NUMBER_TITLE");
    this.nameTitle = this.translate.instant("email_data.NAME_TITLE");
    this.priceTitle = this.translate.instant("email_data.PRICE_TITLE");
    this.discountTitle = this.translate.instant("email_data.DISCOUNT_TITLE");
    this.withDiscountTitle = this.translate.instant("email_data.WITH_DISCOUNT_TITLE");
    this.quantityTitle = this.translate.instant("email_data.QUANTITY_TITLE");
    this.subtotalTitle = this.translate.instant("email_data.SUBTOTAL_TITLE");
    this.shippingTitle=this.translate.instant("email_data.SHIPPING_TITLE");
    this.totalTitle = this.translate.instant("email_data.TOTAL_TITLE");
    this.textThankYou = this.translate.instant("email_data.TEXT_THANKYOU");
    this.textForAddress = this.translate.instant("email_data.TEXT_ADDRESS");
    this.textForTable = this.translate.instant("email_data.TEXT_TABLE");
    this.textForQuestion = this.translate.instant("email_data.TEXT_QUESTIONS");
    this.ownerEmailSubject=this.translate.instant("email_data.OWNER_EMAIL_SUBJECT");
    this.newOrder=this.translate.instant("email_data.NEW_ORDER");
    this.newOrderText=this.translate.instant("email_data.NEW_ORDER_TEXT");
    this.costumerName=this.translate.instant("email_data.COSTUMER_NAME");
    this.emailAddress=this.translate.instant("email_data.EMAIL_ADDRESS");
    this.productsTableText=this.translate.instant("email_data.PRODUCTS_TABLE_TEXT");
  }

  sendEmail() {
    this.translatedTextForemail();
    const subject = 'Lozz Toys'; // Email subject

    let tableRows = '';
    let total = 0;
    let totalAfterDiscount = 0;
    const products = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || "[]");

    const appliedCoupon = this.appliedCoupon;

    this.products.forEach((product: any) => {
      const productName = this.translate.currentLang === 'sq' ? product.nameSQ : product.nameMK;
      let productDiscount = 0;

      if (appliedCoupon) {
        const couponProducts = this.appliedCouponObject.data[0]?.attributes?.products?.data || [];
        const couponCategories = this.appliedCouponObject.data[0]?.attributes?.categories?.data || [];

        const appliesToProduct = couponProducts.some((p: any) => p.id === product.id);
        const appliesToCategory = couponCategories.some((c: any) => c.id === product.categoryId);
        const appliesToAll = couponProducts.length === 0 && couponCategories.length === 0;

        if (appliesToProduct || appliesToCategory || appliesToAll) {
          productDiscount = product.productDetails[0].sellingPriceWithRebate * (this.appliedCouponObject.data[0]?.attributes.Discount / 100);
        }
      }

      const discountedPrice = product.productDetails[0].sellingPriceWithRebate - productDiscount;
      const subtotal = discountedPrice * product.quantity;
      total += subtotal;
      totalAfterDiscount += discountedPrice;

      // Build table rows
      tableRows += `
            <tr>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.productDetails[0].productName ? `${productName} <span style="color: #f6821f"> ${product.productDetails[0].productName} </span>` : `${productName}`}</td>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.productDetails[0].sellingPriceWithRebate.toFixed(2) + this.currency}</td>
                ${appliedCoupon ? `<td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${productDiscount.toFixed(2) + this.currency}</td>` : ''}
                ${appliedCoupon ? `<td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${discountedPrice.toFixed(2) + this.currency}</td>` : ''}
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.quantity}</td>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${subtotal.toFixed(2) + this.currency}</td>
            </tr>
        `;
    });

    // Add shipping row only if there is shippingCost
    if (this.shippingCost) {
      tableRows += `
          <tr>
              <td colspan="${appliedCoupon ? 5 : 3}" style="text-align:right; border: 1px solid #dddddd; padding: 8px;">${this.shippingTitle}</td>
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.shippingCost.toFixed(2) + this.currency}</td>
          </tr>
      `;
    }

    // Add the total row
    tableRows += `
        <tr>
            <td colspan="${appliedCoupon ? 5 : 3}" style="text-align:right; border: 1px solid #dddddd; padding: 8px;">${this.totalTitle}</td>
            <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.totalPriceWithShipping.toFixed(2) + this.currency}</td>
        </tr>
    `;

    const tableData = `
        <table style="font-family: Arial, sans-serif; border-collapse: collapse; width: 100%;">
            <tr>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.nameTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.priceTitle}</th>
                ${appliedCoupon ? `<th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.discountTitle}</th>` : ''}
                ${appliedCoupon ? `<th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.withDiscountTitle}</th>` : ''}
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.quantityTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.subtotalTitle}</th>
            </tr>
            ${tableRows}
        </table>
    `;

    const invoiceHTML = `
        <!DOCTYPE html>
        <html>
        <head></head>
        <body>
        ${this.title + this.name},
        <br/><br/>
        ${this.textThankYou} <br/>
        ${this.textForAddress}<br/><br/>
        <table style="font-family: Arial, sans-serif; border-collapse: collapse; width: 100%;">
            <tr>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.addressTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.cityTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.phoneNumberTitle}</th>
            </tr>
            <tr>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.address}</td>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.city}</td>
                <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.phoneNumber}</td>
            </tr>
        </table>
        <br/><br/>
        ${this.textForTable}<br/><br/>
        ${tableData}
        <br/><br/>
        ${this.textForQuestion}
        </body>
        </html>
    `;

    const emailData = {
      to: this.email,
      subject: subject,
      html: invoiceHTML
    };
    const emailDataString = JSON.stringify(emailData);
    localStorage.setItem('emailData', emailDataString);
  }

  sendEmailForOwner() {
    const subject = this.ownerEmailSubject;

    let tableRows = '';
    let total = 0;
    let totalAfterDiscount = 0;
    const products = JSON.parse(localStorage.getItem(LOCAL_STORAGE.PRODUCTS) || "[]");

    const appliedCoupon = this.appliedCoupon;

    this.products.forEach((product: any) => {
      const productName = this.translate.currentLang === 'sq' ? product.nameSQ : product.nameMK;
      let productDiscount = 0;

      if (appliedCoupon) {
        const couponProducts = this.appliedCouponObject.data[0]?.attributes?.products?.data || [];
        const couponCategories = this.appliedCouponObject.data[0]?.attributes?.categories?.data || [];

        const appliesToProduct = couponProducts.some((p: any) => p.id === product.id);
        const appliesToCategory = couponCategories.some((c: any) => c.id === product.categoryId);
        const appliesToAll = couponProducts.length === 0 && couponCategories.length === 0;

        if (appliesToProduct || appliesToCategory || appliesToAll) {
          productDiscount = product.productDetails[0].sellingPriceWithRebate * (this.appliedCouponObject.data[0]?.attributes.Discount / 100);
        }
      }

      const discountedPrice = product.productDetails[0].sellingPriceWithRebate - productDiscount;
      const subtotal = discountedPrice * product.quantity;
      total += subtotal;
      totalAfterDiscount += discountedPrice;

      // Build table rows
      tableRows += `
          <tr>
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.productDetails[0].productName ? `${productName} <span style="color: #f6821f"> ${product.productDetails[0].productName} </span>` : `${productName}`}</td>
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.productDetails[0].sellingPriceWithRebate.toFixed(2) + this.currency}</td>
              ${appliedCoupon ? `<td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${productDiscount.toFixed(2) + this.currency}</td>` : ''}
              ${appliedCoupon ? `<td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${discountedPrice.toFixed(2) + this.currency}</td>` : ''}
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${product.quantity}</td>
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${subtotal.toFixed(2) + this.currency}</td>
          </tr>
      `;
    });

    // Add shipping row if shipping cost is present
    if (this.shippingCost) {
      tableRows += `
          <tr>
              <td colspan="${appliedCoupon ? 5 : 3}" style="text-align:right; border: 1px solid #dddddd; padding: 8px;">${this.shippingTitle}</td>
              <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.shippingCost.toFixed(2) + this.currency}</td>
          </tr>
      `;
    }

    // Add the total row
    tableRows += `
        <tr>
            <td colspan="${appliedCoupon ? 5 : 3}" style="text-align:right; border: 1px solid #dddddd; padding: 8px;">${this.totalTitle}</td>
            <td style="border: 1px solid #dddddd;text-align: left;padding: 8px;">${this.totalPriceWithShipping.toFixed(2) + this.currency}</td>
        </tr>
    `;

    const tableData = `
        <table style="font-family: Arial, sans-serif; border-collapse: collapse; width: 100%;">
            <tr>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.nameTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.priceTitle}</th>
                ${appliedCoupon ? `<th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.discountTitle}</th>` : ''}
                ${appliedCoupon ? `<th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.withDiscountTitle}</th>` : ''}
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.quantityTitle}</th>
                <th style="border: 1px solid #dddddd;text-align: left;padding: 8px; background-color:gray; color:white;">${this.subtotalTitle}</th>
            </tr>
            ${tableRows}
        </table>
    `;

    const ownerEmailHTML = `
        <!DOCTYPE html>
        <html>
        <head></head>
        <body>
           <h2>${this.newOrder}</h2>
           <p>${this.newOrderText}</p>
            <br/>
            <table style="font-family: Arial, sans-serif; border-collapse: collapse; width: 100%;">
                <tr>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: gray; color: white;">${this.costumerName}</th>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: gray; color: white;">${this.emailAddress}</th>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: gray; color: white;">${this.addressTitle}</th>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: gray; color: white;">${this.cityTitle}</th>
                    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: gray; color: white;">${this.phoneNumberTitle}</th>
                </tr>
                <tr>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.name}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.email}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.address}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.city}</td>
                    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">${this.phoneNumber}</td>
                </tr>
            </table>
            <br/>
            <p>${this.productsTableText}</p>
            <br/>
            ${tableData}
        </body>
        </html>
    `;

    const ownerEmailData = {
      to: this.companyEmail,
      subject: subject,
      html: ownerEmailHTML
    };

    const ownerEmailDataString = JSON.stringify(ownerEmailData);
    localStorage.setItem('ownerEmailData', ownerEmailDataString);
}


  getFormValues(orderId: string) {
    const formValues: { [key: string]: string } = {};
    const inputValues: string[] = [];

    const inputs = document.querySelectorAll('.payment-input');

    inputs.forEach((input) => {
      const inputElement = input as HTMLInputElement;
      const order_id = orderId.toString()
      if (inputElement.name === 'Details2') {
        formValues[inputElement.name] = order_id;
        inputValues.push(order_id);
      } else if (inputElement.name === 'AmountToPay') {
        const totalPrice = this.totalPrice * 100;
        formValues[inputElement.name] = totalPrice.toString();
        inputValues.push(totalPrice.toString());
      } else if (inputElement.name === 'PaymentOKURL' || inputElement.name === 'PaymentFailURL') {
        const inputValue = inputElement.value + "&orderId=" + order_id;
        formValues[inputElement.name] = inputValue;
        inputValues.push(inputValue);
      } else {
        formValues[inputElement.name] = inputElement.value;
        inputValues.push(inputElement.value);
      }
    });

    const values: string[] = [];
    Object.keys(formValues).forEach((key) => {
      if (key !== "CheckSumHeader") {
        values.push(formValues[key]);
      }
    });

    const valuesLength = values.map(value => value.length.toString().padStart(3, '0')).join('');

    formValues["CheckSumHeader"] = formValues["CheckSumHeader"] + "," + valuesLength;

    // This code removes CheckSumHeader and adds it as first element
    inputValues.pop();
    inputValues.unshift(formValues["CheckSumHeader"]);

    const checkSumValue = inputValues.join('');

    formValues['CheckSum'] = this.generateMD5Hash(checkSumValue + "QydDpvP3R41FVPYYpMphkasckqwXWACZ");

    return formValues;
  };

  // Function to submit the form
  submitCardForm(orderId: string) {
    const formValues = this.getFormValues(orderId);
    const form = document.createElement('form');
    form.method = 'post';
    form.action = 'https://www.cpay.com.mk/client/Page/default.aspx?xml_id=/mk-MK/.loginToPay/.simple/';

    // Create input fields with form values
    Object.keys(formValues).forEach((key) => {
      const value = formValues[key];
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = value;
      form.appendChild(input);
    });

    // Append the form to the document body and submit
    document.body.appendChild(form);
    form.submit();
  };

  generateMD5Hash(data: string) {
    return CryptoJS.MD5(data).toString(CryptoJS.enc.Hex);
  };

  generateSaleNumber(): string {
    return `0/${new Date().getFullYear()}`;
  }


  paymentTypes = {
    cash: { id: 0, name: 'Cash' },
    card: { id: 1, name: 'Card' },
  };


  createSale(paymentID: number) {
    const SaleDetails: SaleDetail[] = this.products.map((product) => ({
      productID: product.id,
      productDetailsID: product.productDetails[0].id,
      Qt: product.quantity,
      sellingPriceWithRebate: product.sellingPriceWithRebate,
      vatID: product.vatID,
      total: product.sellingPriceWithRebate * product.quantity

    }));

    const SalePayments: SalePayments[] = [{
      paymentID: paymentID,
      total: this.totalPrice
    }];


    const sale: Sale = {
      companyID: 2,
      saleType: 8,
      SaleDetails: SaleDetails,
      SalePayments: SalePayments,
      NoSale: this.generateSaleNumber(),
      ModUser: 4,
      Type: 1,
      DateSale: new Date(),
      Total: this.totalPrice,
      paymentID: paymentID,
      orderStatus: true,
      status: false,
    };


    this.conomeService.registerSale(sale).subscribe(
      (response) => {
        localStorage.removeItem(LOCAL_STORAGE.PRODUCTS);
        this.router.navigate(['/products']);
      },
      (error) => {
        console.error('Error registering sale:', error);
      }
    );
  }


  createOrder(paymentID: number) {
    const orderDetails: OrderDetailConome[] = this.products.map((product) => ({
      ModUser: product.modUser,
      ProductID: product.id,
      ProductDetailID: product.productDetails[0].id,
      OrderID: 1,
      Qt: product.quantity,
      sellingPriceWithVat:product.productDetails[0].sellingPriceWithVat,
      sellingPriceWithRebate: product.productDetails[0].sellingPriceWithRebate,
      SellingPriceNoVat: product.productDetails[0].sellingPriceNoVat,
      buyingPriceWithVat: product.productDetails[0].buyingPriceWithVat,
      VatID: product.productDetails[0].vatId,
      Rebate:this.discountPercentage,
      CouponCode:this.appliedCoupon,
      SellingPriceWithRebate: (product.productDetails[0].sellingPriceWithRebate - (product.productDetails[0].sellingPriceWithRebate * (this.discountPercentage / 100))).toFixed(2),
      Total: +((product.productDetails[0].sellingPriceWithRebate - (product.productDetails[0].sellingPriceWithRebate * (this.discountPercentage / 100))) * product.quantity).toFixed(2),
      ProductName: this.currentLanguage === 'sq' ? product.nameSQ : product.nameMK
    }));
    let orderClientConome: OrderClientConome | null = null;
    orderClientConome = {
      Name: this.formData.controls["name"].value,
      LastName: this.formData.controls["city"].value,
      Email: this.formData.controls["email"].value,
      Phone: this.formData.controls["phone"].value,
      Address: this.formData.controls["address"].value
    }

    const companyID = parseInt(this.cookieService.get("companyID"))
    const order: OrderConome = {
      CompanyID: companyID,
      ClientConomeID: this.userID,
      DateOrder: new Date(),
      Total: Number(this.totalPriceWithShipping.toFixed(2)),
      OrderStatus: 0,
      PaymentStatus: 0,
      PaymentType: paymentID,
      OrderDetails: orderDetails,
      isDelivery: false,
      OrderClient: orderClientConome,
      SaleID: 1,
      SaleType: 8,
    }

    this.conomeService.createOrder(order).subscribe({
      next: (res) => {
        const orderId = res.orderId;
        if (paymentID === this.paymentTypes.card.id) {
          this.paymentWithCard = true;
          this.submitCardForm(orderId);
        }
        this.sendEmail();
        this.sendEmailForOwner()
        if(!this.isFromBuyNow){
          if (this.userId === '') {
            this.cookieService.delete(LOCAL_STORAGE.PRODUCTS);
            this.cartService.updateCartProducts([])
          } else {
            this.cmsService.removeAllProductsFromCartByUserId(this.userID).subscribe({
              next: (res) => {
                this.cartService.updateCartProducts([])
              }
            });
          }
        }
        if (paymentID === this.paymentTypes.cash.id) {
          if(res.status===200){
            this.router.navigate(['/products'], { queryParams: { orderSuccess: true } })
          }else{
            this.router.navigate(['/products'], { queryParams: { orderSuccess: false } })
          }
        }
      },
      error: (err) => {
        console.error(err);
      }
    })
  }
  sendOrderEmail(orderId: number, userId: number) {
    this.conomeService.sendOrderEmail(orderId, userId).subscribe({
      next: (res) => {
      },
      error: (error) => {
        console.error('Error retrieving order details:', error);
      }
    });
  }

  submitForm(paymentForm: HTMLFormElement) {
    paymentForm.submit();
  }

  encryptString(plainText: string): string {
    const iv = CryptoJS.lib.WordArray.random(16);  // Generate a random IV
    const encrypted = CryptoJS.AES.encrypt(plainText, CryptoJS.enc.Utf8.parse(this.secretKey), { iv: iv }).toString();
    return iv.toString(CryptoJS.enc.Base64) + ":" + encrypted;  // Concatenate IV and ciphertext
  }

  // Function to generate the encrypted URL
  generateEncryptedUrl(orderSuccess: boolean): string {
    const uiUrl = `${window.location.origin}/products?orderSuccess=${orderSuccess}`;
    const encryptedUiUrl = this.encryptString(uiUrl);
    return `${environment.conomeUrl}/api/OrdersApi/SuccessOrFail?uiUrl=${encodeURIComponent(encryptedUiUrl)}&token=${environment.company_token}`;
  }
  
  ngOnDestroy(){
    this.cartService.clearBuyNowProduct();
    localStorage.removeItem('couponCode');
  }
}
