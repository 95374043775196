import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LozztoysCmsService {

    cmsUrl = environment.backendUrl;

    constructor(private http: HttpClient) { }

    getCarousel(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/carousel-banner${population}`)
    }

    getMenuItems(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/menu${population}`)
    }

    getAboutUs(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/about-us${population}`)
    }

    getContact(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/contact${population}`)
    }

    getFooter(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/footer${population}`)
    }

    getHome(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/home${population}`)
    }

    getCategoriesFromCMS(): Observable<any>{
        return this.http.get(`${this.cmsUrl}/api/categories?filters[showOnApp][$eq]=true`)
    }

    getBrandsFromCMS(limit?: number, offset?: number): Observable<any> {
        let url = `${this.cmsUrl}/api/brands`;
        if (limit !== undefined) {
          url += `?pagination[pageSize]=${limit}`;
        }
        if(offset !== undefined){
            url += `&pagination[page]=${offset}`;
        }
        return this.http.get(url);
      }

      searchBrandsFromCMS(searchTerm: string, searchField: string) {
        const url = `${this.cmsUrl}/api/brands?filters[name${searchField}][$containsi]=${searchTerm}`;
        return this.http.get(url);
      }
      

    getTopProductsFromCMS(): Observable<any> {
        const url = `${this.cmsUrl}/api/products?filters[isTopProduct][$eq]=true`;
        return this.http.get(url);
    }

    getTopPicksFromCMS(): Observable<any> {
        const url = `${this.cmsUrl}/api/top-picks?populate=deep`;
        return this.http.get(url);
    }

    setWishList(data: any) {
        const payload = {
            data: {
                productId: data.productId,
                productDetailId: data.productDetailId,
                userId: typeof data.userId !== 'string' ? JSON.stringify(data.userId) : data.userId
            }
        };
        return this.http.post(`${this.cmsUrl}/api/wish-lists`, payload);
    }

    getwishlistHeaders(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/wish-list-header${population}`)
    }

    getWishList(userId: string): Observable<any> {
        return this.http.get(`${this.cmsUrl}/api/wish-lists?filters[userId][$eq]=${userId}`);
    }

    getWishListEntry(productId: string, userId: string): Observable<any> {
        const endpoint = `wish-lists?filters[productId]=${productId}&filters[userId]=${userId}`;
        return this.http.get(`${this.cmsUrl}/api/${endpoint}`);
    }
    
    removeProductFromWishListById(wishListEntryId: number): Observable<any> {
        const endpoint = `wish-lists/${wishListEntryId}`;
        return this.http.delete(`${this.cmsUrl}/api/${endpoint}`);
    }

    setCartList(data: any) {
        const payload = {
            data: {
                productId: data.productId,
                quantity: data.quantity,
                productDetailId: data.productDetailId,
                userId: typeof data.userId !== 'string' ? JSON.stringify(data.userId) : data.userId
            }
        };
        return this.http.post(`${this.cmsUrl}/api/cart-lists`, payload);
    }

    getCartByUser(userId: string): Observable<any> {
        return this.http.get(`${this.cmsUrl}/api/cart-lists?filters[userId][$eq]=${userId}`);
    }

    updateCartProduct(data: any) {
        const payload = {
            data: {
                quantity: data.quantity
            }
        };
        return this.http.put(`${this.cmsUrl}/api/cart-lists/${data.id}`, payload);
    }
    removeProductFromCartById(cartEntryId: number): Observable<any> {
        const endpoint = `cart-lists/${cartEntryId}`;
        return this.http.delete(`${this.cmsUrl}/api/${endpoint}`);
    }

    removeAllProductsFromCartByUserId(userId: number): Observable<any> {
        const endpoint = `cart-lists/remove-all/${userId}`;
        return this.http.delete(`${this.cmsUrl}/api/${endpoint}`);
    }
    
    getCartPage(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/cart-page${population}`)
    }

    getProductDetail(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/product-detail${population}`)
    }
    
    getAgeRanges(): Observable<any>{
        return this.http.get(`${this.cmsUrl}/api/age-ranges?populate=*`)
    }

    getTermsAndConditions(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/terms-of-use${population}`)
    }

    getFaqs(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/faq${population}`)
    }

    getPrivacyPolicy(populate:string): Observable<any>{
        const population = populate || '';
        return this.http.get(`${this.cmsUrl}/api/privacy-policy${population}`)
    }


}
