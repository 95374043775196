import { Component, inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marked } from 'marked';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { SeoService } from 'src/app/services/seoService/seo.service';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrl: './terms-of-use.component.scss'
})
export class TermsOfUseComponent implements OnInit {

  cmsService = inject(LozztoysCmsService);
  translateService = inject(TranslateService);
  seoService = inject(SeoService);

  currentLanguage = this.translateService.currentLang;
  breadCrumbItems!: any;
  termsOfUse: any;
  htmlContent:any;
  
  ngOnInit(): void {
    this.getTermsOfUse();
    this.translateBreadcrumbItems();
    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLanguage = lang.lang;
      this.translateBreadcrumbItems();
      this.getTermsOfUse();

    })
  }

  private async translateBreadcrumbItems() {
    const homeLabel = await this.translateService.get("menu.HOME").toPromise();
    const firstLabel = await this.translateService.get("menu.TERMS-OF-USE").toPromise();

    this.breadCrumbItems = [
        { label: homeLabel, link: '/home' },
        { label: firstLabel, link: '/terms-of-use' },
    ];
}
    preprocessText(text: any): string {
      if (typeof text !== 'string') {
        console.warn('Expected a string but received:', text);
        return ''; 
      }
    
      return text
        .replace(/\\r\\n/g, '\n')
        .replace(/\\n/g, '\n')
        .replace(/\\r/g, '\n')
        .replace(/(?:^|[\s])(?:[\*#\-_\+])/g, ' $&');
    }
  
    generateMarkdownFromArray(data: any) {
      return marked.parse(data);
    }

    getTermsOfUse() {
      const locale = this.currentLanguage;
    const populate = 'deep';
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;
      this.cmsService.getTermsAndConditions(populateAndLocale).subscribe((data) => {
        this.termsOfUse = data;
        this.seoService.initializeSeo(this.termsOfUse.data.attributes.seo)
        this.htmlContent = this.generateMarkdownFromArray(this.preprocessText(this.termsOfUse.data.attributes.TitleAndDescription.description));
      });
    }
}
