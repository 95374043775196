import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DataSharedService {

    constructor() { }
    private isUserLoggedIn = new Subject<boolean>();
    isUserLoggedIn$ = this.isUserLoggedIn.asObservable();
    private isFlashSales: boolean = false;
    private categoryId: number = -1;
    private categoryIds: number[] = [];

    private brandIds: number[] = [];
    private productDetailId: number = -1;
    
    setLoginStatus(status: boolean) {
        this.isUserLoggedIn.next(status);
    }


    setCategoryIds(ids: number[]): void {
        this.categoryIds = ids; 
      }
      
      getCategoryIds(): number[] {
        return this.categoryIds || []; 
      }

    setBrandIds(ids: number[]): void {
        this.brandIds = ids;
    }

    getBrandIds(): number[] {
        return this.brandIds;
    }

    removeBrandIds(idToRemove: number): void {
        this.brandIds = this.brandIds.filter(id => id !== idToRemove);
    }

    setProductDetailId(id: number): void {
        this.productDetailId = id;
    }

    getProductDetailId(): number {
        return this.productDetailId;
    }

    setIsFlashSales(status: boolean): void {
        this.isFlashSales = status;
    }

    getIsFlashSales(): boolean {
        return this.isFlashSales;
    }

}
