import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { marked } from 'marked';
import { LozztoysCmsService } from 'src/app/services/lozztoysCmsServices/lozztoys-cms.service';
import { SeoService } from 'src/app/services/seoService/seo.service';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss'
})
export class FaqComponent {

  cmsService = inject(LozztoysCmsService);
  translateService = inject(TranslateService);
  seoService = inject(SeoService);

  currentLanguage = this.translateService.currentLang;
  breadCrumbItems!: any;
  faq: any;
  htmlContent:any;
  
  ngOnInit(): void {
    this.getFaq();
    this.translateBreadcrumbItems();

    this.translateService.onLangChange.subscribe((lang) => {
      this.currentLanguage = lang.lang;
      this.translateBreadcrumbItems();
      this.getFaq();

    })
  }

  private async translateBreadcrumbItems() {
    const homeLabel = await this.translateService.get("menu.HOME").toPromise();
    const firstLabel = await this.translateService.get("menu.FAQ").toPromise();

    this.breadCrumbItems = [
        { label: homeLabel, link: '/home' },
        { label: firstLabel, link: '/terms-of-use' },
    ];
}
    preprocessText(text: any): string {
      if (typeof text !== 'string') {
        console.warn('Expected a string but received:', text);
        return ''; 
      }
    
      return text
        .replace(/\\r\\n/g, '\n')
        .replace(/\\n/g, '\n')
        .replace(/\\r/g, '\n')
        .replace(/(?:^|[\s])(?:[\*#\-_\+])/g, ' $&');
    }
  
    generateMarkdownFromArray(data: any) {
      return marked.parse(data);
    }

    getFaq() {
      const locale = this.currentLanguage;
    const populate = 'deep';
    const populateAndLocale = `?populate=${populate}&locale=${locale}`;
      this.cmsService.getFaqs(populateAndLocale).subscribe((data) => {
        this.faq = data;
        this.seoService.initializeSeo(this.faq.data.attributes.seo);
        this.htmlContent = this.generateMarkdownFromArray(this.preprocessText(this.faq.data.attributes.TitleAndDescription.description));

      });
    }
}
