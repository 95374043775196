import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { UserProfileService } from '../services/user/user-profile.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    token = '';
    userId: any;
    newPassword = '';
    repeatNewPassword = '';
    resetResult = "";
    showNewPassword = false;
    showConfirmationPassword = false;

    constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private translate:TranslateService, private resetPasswordService: UserProfileService) {}

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.userId = params.userId;
            this.token = params.token;
        });
    }

    toggleNewPassword() {
        this.showNewPassword = !this.showNewPassword;
    }
    toggleConfirmationPassword() {
        this.showConfirmationPassword = !this.showConfirmationPassword;
    }

    resetPassword() {
        const passwordCriteriaRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!this.newPassword || !this.repeatNewPassword) {
          this.resetResult = this.translate.instant('reset-password-page.FILL-FIELDS'); 
          return;
        }
    
        if (this.newPassword !== this.repeatNewPassword) {
          this.resetResult = this.translate.instant('reset-password-page.PASSWORD-MISMATCH');
          return;
        }

        if (!passwordCriteriaRegex.test(this.newPassword)) {
            this.resetResult = this.translate.instant('reset-password-page.PASSWORD-CRITERIA-NOT-MET');
            return;
        }
    
        const payload = {
            token: this.token,
            newPassword: this.newPassword,
            userId: this.userId 
          };
    
        this.resetPasswordService.changepassword(payload, true).subscribe({
          next: (response) => {
            Swal.fire({
                title: this.translate.instant("reset-password-page.RESET-PASSWORD-SUCCES"),
                text:  this.translate.instant("reset-password-page.RESET-PASSWORD-DESCRIPTION"),
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                    confirmButton: 'custom-confirm-button'
                }
                });
        this.newPassword = "";
        this.repeatNewPassword = "";
        this.router.navigate(['/login']);
          },
          error: (err) => {
            this.resetResult=err.error.error.message
          }
        });
      }
}
