import { HttpClient } from '@angular/common/http';
import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    private meta = inject(Meta);
  private titleService = inject(Title);

  constructor() {}

  /**
   * Initializes SEO by parsing the provided SEO data
   * @param seoData - structured SEO data from CMS
   */
  public initializeSeo(seoData: any): void {
    if (!seoData || seoData.length === 0) {
      console.warn('No SEO data provided');
      return;
    }

    const seo = seoData;
    if (seo) {
      this.updateMetaTags(seo);
      /* this.updateSocialTags(seo); */
      this.updateStructuredData(seo.structuredData);
      if (seo?.Google?.googleAnalyticsId) {
        this.setupGoogleAnalytics(seo.Google.googleAnalyticsId);
      }
      if (seo?.Facebook?.fbPixelId) {
        this.setupFacebookPixel(seo.Facebook.fbPixelId);
      }
    } else {
      console.warn('No valid SEO configuration found in seo');
    }
  }

  private updateMetaTags(tags: any): void {
    this.titleService.setTitle(tags?.metaTitle || 'lozztoys');
    this.meta.updateTag({ name: 'description', content: tags?.metaDescription || 'lozztoys description' });
    this.meta.updateTag({ name: 'keywords', content: tags?.keywords || 'lozztoys keywords' });
    this.meta.updateTag({ name: 'robots', content: tags?.metaRobots || 'index, follow' });
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
  
    if (tags?.canonicalURL) {
      this.meta.updateTag({ name: 'canonical', content: tags?.canonicalURL });
    }
    if (tags?.Google?.verificationToken) {
      this.meta.updateTag({ name: 'google-site-verification', content: tags?.Google?.verificationToken });
    }
  }

  private updateSocialTags(metaSocial: any): void {

      const imageUrl = metaSocial?.openGraph['og:image']?.url || '/default-image.png';
      const fullImageUrl = `${environment.backendUrl}${imageUrl}`;

      this.meta.updateTag({ property: `og:title`, content: metaSocial?.openGraph['og:title'] });
      this.meta.updateTag({ property: `og:description`, content: metaSocial?.openGraph['og:description'] });
      this.meta.updateTag({ property: `og:image`, content: fullImageUrl });

  }

  private updateStructuredData(data: any): void {
    if (!data) return;
    const scriptTag = document.createElement('script');
    scriptTag.type = 'application/ld+json';
    scriptTag.text = JSON.stringify(data);
    const existingScript = document.querySelector('script[type="application/ld+json"]');
    if (existingScript) {
      document.head.removeChild(existingScript);
    }
    document.head.appendChild(scriptTag);
  }

  private setupGoogleAnalytics(gaId: string): void {
    if (gaId) {
      const gaScript = document.createElement('script');
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(gaScript);

      const configScript = document.createElement('script');
      configScript.text = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${gaId}', {
          'anonymize_ip': true,
          'cookie_flags': 'SameSite=None;Secure'
        });
      `;
      document.head.appendChild(configScript);
    }
  }

  private setupFacebookPixel(fbPixelId: string): void {
    if (fbPixelId) {
      const fbScript = document.createElement('script');
      fbScript.text = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${fbPixelId}');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(fbScript);

      const noscriptTag = document.createElement('noscript');
      const imgTag = document.createElement('img');
      imgTag.height = 1;
      imgTag.width = 1;
      imgTag.style.display = 'none';
      imgTag.src = `https://www.facebook.com/tr?id=${fbPixelId}&ev=PageView&noscript=1`;
      noscriptTag.appendChild(imgTag);
      document.body.appendChild(noscriptTag);
    }
  }
}

