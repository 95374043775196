<div class="container">
  <div class="form-container">
    <div class="p-2">
      <h2>{{'user-profile.RESET-PASSWORD' | translate}}</h2>
      <label for="email">{{'user-profile.NEW-PASSWORD'|translate}}</label>

      <div class="input-group mb-4 ">
        <input class="form-control input-lg" placeholder="{{'user-profile.NEW-PASSWORD'| translate}}"
          [(ngModel)]="newPassword" [type]="showNewPassword? 'text': 'password'" />
        <span class="show-password"><i class="fa"
            [ngClass]="{'fa-eye-slash':!showNewPassword,'fa-eye':showNewPassword}"
            (click)="toggleNewPassword()"></i></span>
      </div>

      <label for="email">{{'user-profile.RETYPE-NEW-PASSWORD'|translate}}</label>

      <div class="input-group mb-4">
        <input class="form-control input-lg" placeholder="{{'user-profile.RETRY-NEW-PASSWORD'| translate}}"
          [(ngModel)]="repeatNewPassword" [type]="showConfirmationPassword? 'text' : 'password'" />
        <span class="show-password"><i class="fa"
            [ngClass]="{'fa-eye-slash':!showConfirmationPassword,'fa-eye':showConfirmationPassword}"
            (click)="toggleConfirmationPassword()"></i></span>
      </div>
      <!--       <input type="password" id="email" [(ngModel)]="newPassword" class="form-control form-control-sm"/>
      <br/>
      <input type="password" id="email" [(ngModel)]="repeatNewPassword" class="form-control form-control-sm"/> -->

      <br>
      <button class="btn btn-primary" (click)="resetPassword()">{{'user-profile.RESET'|translate}}</button>
      <p class="error-text" *ngIf="resetResult.length>0">{{resetResult}}</p>
    </div>
  </div>
</div>