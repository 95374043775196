<app-banner title="{{'menu.FAQ' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-banner>

<section class="container tab-content py-sm-5 margin-2-top">
    <div class="row pt-3 pt-sm-0 px-1">
      <div>
        <h1>{{faq?.data.attributes?.TitleAndDescription?.title}}</h1>
        <div [innerHTML]="htmlContent"></div>
      </div>
    </div>
  
  </section>