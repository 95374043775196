import { Component, Inject, PLATFORM_ID, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOCAL_STORAGE } from '../../modules/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
@Input()  headerText: string = '';
@Input()  text: any;
@Input() link: any; 

constructor(private router: Router, private sharedService: DataSharedService){
}
redirectTo(link: any) {
  if (link) {
    if (typeof link === 'string') {
      if (link.startsWith('http://') || link.startsWith('https://')) {
        window.location.href = link;
      } else {
        this.router.navigate([link]);
      }
    } else if (typeof link === 'object') {
      if (link.categoryIds) {
        this.sharedService.setCategoryIds(link.categoryIds); 
        this.router.navigate([link.path]);
      } else if (link.brandIds) {
        this.sharedService.setBrandIds(link.brandIds); 
        this.router.navigate([link.path]);
      } 
      else if (link.categorybrandIds) {
          const categoryIds = link.categorybrandIds
            .filter((item: any) => item.type === 'category')
            .map((item: any) => item.id);
          const brandIds = link.categorybrandIds
            .filter((item: any) => item.type === 'brand')
            .map((item: any) => item.id);
  
  
          this.sharedService.setCategoryIds(categoryIds);
          this.sharedService.setBrandIds(brandIds);
  
          this.router.navigate([link.path]);
      }        
      else {
        console.error('Invalid link object:', link);
      }
    }
  }
}
}
