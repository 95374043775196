import { Component } from '@angular/core';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrl: './coming-soon.component.scss'
})
export class ComingSoonComponent {
  timeLeft: string = '';
  private countDownDate = new Date('2025-01-13T00:00:00').getTime();

  ngOnInit(): void {
    this.startCountdown();
  }

  private startCountdown(): void {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = this.countDownDate - now;

      if (distance < 0) {
        clearInterval(interval);
        this.timeLeft = 'Website is now active!';
        // Redirect to your website's main page
        window.location.href = 'https://lozztoys.com/home'; 
      } else {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.timeLeft = `${days}d ${hours}h ${minutes}m ${seconds}s`;
      }
    }, 1000);
  }
}
