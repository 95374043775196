@if (products.length > 0) {
<app-banner title="{{ 'menu.CART' | translate }}" [breadcrumbItems]="breadCrumbItems"></app-banner>
<div class="container pb-3">
  <div class="rounded-3 mt-4 mb-5">
    <div class="pb-4 pb-sm-5">
      <ul class="nav nav-tabs justify-content-center w-100">
        <li class="nav-item flex-fill text-center">
          <a class="nav-link active">
            {{ 'menu.CART' | translate }}
          </a>
        </li>
        <li class="nav-item flex-fill text-center">
          <a class="nav-link" (click)="goToCheckoutPage()">
            {{ 'menu.CHECKOUT' | translate }}
          </a>
        </li>
      </ul>
      <table class="table table-hover mb-0">
        <thead class="shadow-lg">
          <tr>
            <th>{{ 'CART.PRODUCT' | translate }}</th>
            <th>{{ 'CART.PRICE' | translate }}</th>
            <th>{{ 'CART.QUANTITY' | translate }}</th>
            <th>{{ 'CART.SUBTOTAL' | translate }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let cart of products; let i = index" class="product-row shadow-lg">
            <td>
              <div class="product-container">
                <img [src]="cart.images[0] ? conomeUrl + '/' + cart.images[0] : (cart?.image ? conomeUrl + '/' + cart?.image : '../assets/images/no-product.jpg')" width="60" alt="{{ 'CART.PRODUCT' | translate }}">
                {{ currentLanguage === "sq" ? cart.nameSQ : cart.nameMK }} &nbsp;<span class="detail-name">{{ cart.productDetails[0].productName ? cart.productDetails[0].productName : '' }}</span>
              </div>
            </td>
            <td>
              <div class="product-info">
                <span class="d-md-none">{{ 'CART.PRICE' | translate }}</span>
                {{ cart.productDetails[0].sellingPriceWithRebate | number: '0.2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
              </div>
            </td>
            <td>
              <div class="product-info">
                <span class="d-md-none">{{ 'CART.QUANTITY' | translate }}</span>
                <input (change)="limitNumberLength($event, cart.productDetails[0].qt); calcTotalOnChange(i, $event)" (input)="limitNumberLength($event, cart.productDetails[0].qt); calcTotalOnChange(i, $event)" class="form-control" type="number" id="quantity0" value="{{ cart.quantity }}" min="1" max="{{ cart.productDetails[0].qt }}" />
              </div>
            </td>
            <td>
              <div class="product-info">
                <span class="d-md-none">{{ 'CART.SUBTOTAL' | translate }}</span>
                {{ cart.total | number: '0.2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
              </div>
            </td>
            <td>
              <button (click)="removeItemFromCart(cart.id, cart.productDetails[0].id, i)" class="btn btn-link px-0 text-danger remove-button" type="button">
                <i class="ci-close-circle"></i>
                <span class="d-md-none ms-1">{{ 'CART.REMOVE' | translate }}</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="buttons margin-3-top">
        <div class="row">
          <div class="col-md-12 col-12 col-lg-12 col-sm-12 d-flex btn-container">
            <div (click)="returnToShopPage(goToShopButton?.link)" class="button to-shop-btn">
              {{ goToShopButton?.text }}
            </div>
          </div>
        </div>
      </div>    

      <div class="coupon margin-3-top">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-12 col-12">
            <div class="row margin-2-bottom">
              <div class="col-12 col-sm-6 col-lg-7">
                <input class="input form-control w-100" type="text" [(ngModel)]="couponCode" placeholder="{{ 'CART.APPLY COUPON' | translate }}">
              </div>
              <div class="col-12 col-sm-6 col-lg-5 d-flex flex-row align-items-center mt-2 mt-sm-0">
                <div class="apply-coupon w-100 w-sm-auto text-center" (click)="calcCouponTotalOnInit()">
                    {{ 'CART.APPLY COUPON' | translate }}
                </div>
                <button class="button-outline icon-btn" type="button" (click)="removeCoupon()" *ngIf="appliedCoupon">
                  <i class="ci-close"></i>
                </button>
              </div>
              <div *ngIf="couponCheckTriggered && couponDoesNotExist && couponCode" class="text-danger mt-2">
                {{ 'CART.COUPON-DOESNT-EXIST' | translate }}
              </div>
              <div *ngIf="couponCheckTriggered && !couponDoesNotExist && !couponDoesNotApply && !couponLimitReached && appliedCoupon" class="text-success mt-2">
                {{ 'CART.COUPON-APPLIED' | translate }}
              </div>
              <div *ngIf="couponDoesNotApply" class="text-warning mt-2">
                {{ 'CART.COUPON-DOESNT-APPLY' | translate }}
              </div>
              <div *ngIf="couponCheckTriggered && couponLimitReached" class="text-danger mt-2">
                {{ 'CART.COUPON-LIMIT' | translate }}
              </div>           
            </div>
          </div>  
          <div class="col-lg-5 col-md-5 col-sm-12 col-12">
            <div class="cart-total-box">
              <h4 class="margin-1-bottom">{{ 'CART.TOTAL' | translate }}</h4>
              <div class="d-flex justify-content-between">
                <div class="text">
                  {{ 'CART.SUBTOTAL' | translate }}
                </div>
                <div class="value">
                  {{ totalprice | number: '1.2-2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
                </div>
              </div>
              <hr class="mt-2 margin-1-bottom" >
              
              <div class="d-flex justify-content-between" *ngIf="totalDiscount">
                <div class="text">
                  {{'CART.DISCOUNT' | translate}}
                </div>
                <div class="value">
                  {{ totalDiscount | number: '1.2-2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
                </div>
              </div>
              <hr class="mt-2 margin-1-bottom" *ngIf="totalDiscount">
              <div class="d-flex justify-content-between">
                <div class="text">
                  {{ 'CART.SHIPPING' | translate }}
                </div>
                <div class="value">
                  {{ shippingCost | number: '1.2-2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
                </div>
              </div>
              <hr class="mt-2 p margin-1-bottom">
              <div class="d-flex justify-content-between">
                <div class="text">
                  {{ 'CART.TOTAL' | translate }}
                </div>
                <div class="value">
                  {{ totalPriceCoupon | number: '1.2-2' }} {{ 'currency.CURRENCY_VALUE' | translate }}
                </div>
              </div>

              <div class="checkout-button margin-1-top" (click)="goToCheckoutPage()">
                {{ 'CART.PROCEED_TO_CHECKOUT' | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
}
@else {
  <div class="container pt-4 pb-3 py-sm-4">
  <div  class="empty-cart" *ngIf="numberOfProducts <= 0">
    <i class="fas fa-times empty-cart-icon"></i>
    <h2>{{"CART.CART-IS-EMPTY" | translate}}</h2>
    <p>{{"CART.YOU-HAVENT-ADDED-ANYTHING-TO-YOUR-CART" | translate}}</p>
    <a href="/home" class="continue-shopping-btn">{{"CART.CONTINUE-SHOPPING" | translate}}</a>
</div>
</div>
}